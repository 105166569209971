import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div className="conteudo_geral">
          <noscript
            aria-hidden="true"
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TQDRJ93" height="0" width="0" style="display:none;visibility:hidden" title="Google Tag Manager">Google Tag Manager</iframe>'
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "\n// **** Scroll na pag 'como-funciona' : ****\njQuery(window).on('load', function() {\nvar numberSlides = 3;\t\t//**** Alterar este nr sempre que for necessario incluir novas div com conteudo nesta pagina especifica; corresponde ao nr total de divs\nvar distance = 500;\nvar timing = 750;\njQuery('#div_pageNr').html('1');\njQuery('#div_setacima').hide();\njQuery('#div_setacima').click(function() {\nvar next = parseFloat(jQuery('#div_pageNr').html()) - 1;\nvar myDist = '-'+((next * distance) - distance)+'px';\njQuery('.region-content').animate({\"margin-top\": myDist}, timing);\njQuery('#div_pageNr').html(next);\nif(next >= numberSlides ){\njQuery('#div_setabaixo').hide();\n} else {\njQuery('#div_setabaixo').fadeIn();\n}\nif(next <=1){\njQuery('#div_setacima').hide();\n} else {\njQuery('#div_setacima').fadeIn();\n}\n});\njQuery('#div_setabaixo').click(function() {\nvar next = parseFloat(jQuery('#div_pageNr').html()) + 1;\nvar myDist = '-'+((next * distance) - distance)+'px';\njQuery('.region-content').animate({\"margin-top\": myDist}, timing);\njQuery('#div_pageNr').html(next);\nif(next >=2){\t\t\t//**** Alterar este nr sempre que for necessario ajustar o nr de divs com conteudo a mostrar nesta pagina especifica\njQuery('#div_setabaixo').hide();\n} else {\njQuery('#div_setabaixo').fadeIn();\n}\nif(next <=1){\njQuery('#div_setacima').hide();\n} else {\njQuery('#div_setacima').fadeIn();\n}\n});\n});\n"
            }}
          />
          <div id="head_geral" className="container">
            <div className="container">
              <header id="head" role="banner">
                <div className="five columns alpha">
                  <div className="bisnaga" />
                </div>
                <div className="menu_linha_cima" />
                <div className="menu_linha_abaixo" />
                <div
                  className="eleven columns omega"
                  style={{
                    float: "right"
                  }}
                >
                  <div className="cabecalho_esq">
                    <div className="div_logo">
                      <a href="/" title="Início">
                        <img
                          className="img_logo"
                          src="/sites/default/files/color/responsive/logo.png"
                          alt="Início"
                        />
                      </a>{" "}
                    </div>
                  </div>
                  <nav id="navigation" role="navigation">
                    <div id="main-menu">
                      <ul className="menu">
                        <li className="first leaf active-trail">
                          <a
                            href="/o-que-e-biafine"
                            className="active-trail active"
                          >
                            O que é biafine<sup>®</sup>
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/quando-e-como-aplicar">
                            Quando e como <br /> aplicar
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/como-funciona">Como funciona</a>
                        </li>
                        <li className="leaf">
                          <a href="/balsamo-multi-reparador-apaziguante"><span className="blue-text">Cica</span> biafine<sup>®</sup> <br /><span className="align-leaf">Cosmético</span></a>
                        </li>
                        <li className="last leaf">
                          <a href="/contactos">Contactos</a>
                        </li>
                      </ul>{" "}
                    </div>
                  </nav>
                </div>
              </header>
            </div>
          </div>
          <div className="container" id="content-contain">
            <div className="menu_shadow" />
            <div id="content" className="sixteen columns">
              <div id="breadcrumbs">
                <h2 className="element-invisible">Está aqui</h2>
                <nav className="breadcrumb">
                  <a href="/">Início</a> » O que é o BIAFINE<sup>®</sup> e para
                  que é utilizado
                </nav>
              </div>
              <section id="post-content" role="main">
                <h1 className="page-title">
                  O que é o BIAFINE<sup>®</sup> e para que é utilizado
                </h1>
                <div className="region region-content">
                  <div id="block-system-main" className="block block-system">
                    <div className="content">
                      <span
                        property="dc:title"
                        content="O que é o BIAFINE<sup>®</sup> e para que é utilizado"
                        className="rdf-meta element-hidden"
                      />
                      <span
                        property="sioc:num_replies"
                        content="0"
                        datatype="xsd:integer"
                        className="rdf-meta element-hidden"
                      />
                      <div className="content node-page">
                        <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div
                              className="field-item even"
                              property="content:encoded"
                            >
                              <p className="textoBlocoInicialCinza">
                                BIAFINE
                                <sup
                                  style={{
                                    "font-size": "11px",
                                    "vertical-align": "top"
                                  }}
                                >
                                  ®
                                </sup>{" "}
                                é uma emulsão óleo em água formulada para o tratamento de feridas cutâneas superficiais não
                                infetadas, queimaduras de 1º grau e eritema
                                solar. Quando aplicado adequadamente numa
                                ferida, BIAFINE
                                <sup
                                  style={{
                                    "font-size": "11px",
                                    "vertical-align": "top"
                                  }}
                                >
                                  ®
                                </sup>{" "}
                                fornece um ambiente húmido ótimo para o processo
                                de cicatrização.
                              </p>
                              <div
                                style={{
                                  width: "280px"
                                }}
                              >
                                <h2 className="tituloMeioTexto">
                                  INDICAÇÕES TERAPÊUTICAS
                                </h2>
                                <hr
                                  style={{
                                    height: "1px",
                                    border: "none",
                                    width: "280px"
                                  }}
                                />
                                <p className="textoBlocoCinzaEscuro">
                                  Tratamento de feridas cutâneas superficiais
                                  não infetadas, queimaduras de 1º grau e
                                  eritema solar. Este medicamento é para ser
                                  utilizado na pele.
                                </p>
                              </div>
                              <div
                                style={{
                                  width: "280px"
                                }}
                              >
                                <h2 className="tituloMeioTexto">COMPOSIÇÃO</h2>
                                <hr
                                  style={{
                                    height: "1px",
                                    border: "none",
                                    width: "280px"
                                  }}
                                />
                                <p
                                  className="textoBlocoCinzaEscuro"
                                  style={{
                                    "font-size": "11px"
                                  }}
                                >
                                  Cada 100,0 g de emulsão contém 0,670 g de trolamina.
                                  <br />
                                  <b>Excipientes: </b>estearato de etilenoglicol, ácido esteárico, palmitato de cetilo, parafina sólida, parafina líquida leve, esqualeno, óleo de abacate, propilenoglicol (E1520), alginato de trolamina e sódio, sorbato de potássio (E202), para-hidroxibenzoato de metilo sódico (E219), para-hidroxibenzoato de propilo sódico (E217), fragrância “Yerbatone” e água purificada.
                                </p>
                              </div>
                              <p>
                                <b>&nbsp; </b>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <footer></footer>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="clear" />
          </div>
          <div id="copyright_geral" className="container">
            <div id="copyright" className="container">
              <div className="background_img_interior" />
              <div className="background_img_pag_o_que_e_biafine" />
              <div className="rodape_esq">
                <div className="region region-footer">
                  <div id="block-block-1" className="block block-block">
                    <div className="content">
                      <p>
                        www.biafine.pt © JNTL Consumer Health (Portugal) Limitada, 2024.                        
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rodapeDir rodape_dir">
                <div className="region region-footer-menu">
                  <div
                    id="block-menu-menu-menu-rodape"
                    className="block block-menu"
                  >
                    <div className="content">
                      <ul className="menu">
                        <li className="first leaf">
                          <a href="/politica-de-privacidade" title>
                            POLÍTICA DE PRIVACIDADE
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/cookie-policy" title>
                            POLÍTICA DE COOKIES
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/aviso-legal" title>
                            AVISO LEGAL
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/mapa-do-site" title>
                            MAPA DO SITE
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/contactos" title>
                            CONTACTOS
                          </a>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rodape_centro">
                <div className="region region-footer-center">
                  <div id="block-block-4" className="block block-block">
                    <div className="content">
                      <div>
                        Este site é publicado pela JNTL Consumer Health (Portugal) Limitada única responsável pelo seu
                        conteúdo.&nbsp;Destina-se a utilizadores em Portugal.
                      </div>
                      <div>
                        V08 <b>Biafine<sup>®</sup></b>, trolamina. Medicamento indicado em queimaduras de 1º grau, eritema solar e feridas
                        superficiais não infetadas. Afastar dos olhos. Contém excipientes que podem originar reações
                        cutâneas/alérgicas. Contraindicado em caso de hipersensibilidade aos componentes. Leia
                        cuidadosamente o folheto informativo. Em caso de dúvida ou de persistência dos sintomas consulte o
                        seu médico ou farmacêutico. JNTL Consumer Health (Portugal), Lda. PT-BIA-2400001. 04/2024
                        <br />
                        &nbsp;
                      </div>
                      <div>&nbsp;</div>
                      <div className="last leaf">
                        <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                          Configuração de cookies
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clear" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
